/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "a-mexanized-array",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-mexanized-array",
    "aria-label": "a mexanized array permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A. MEXanized Array"), "\n", React.createElement(_components.p, null, "and solution of this problem with proof in Rust Language"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "In this article, we will see my solution to the codeforces problem, ", React.createElement(_components.a, {
    href: "https://codeforces.com/contest/1870/problem/A"
  }, "1870A. MEXanized Array"), ", which came in Codeforces CodeTON Round 6 (Div. 1 + Div. 2, Rated, Prizes!)."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " My Solution might not be the most optimized one, but it is certainly working."), "\n", React.createElement(_components.p, null, "You can go to above link to view the question statement."), "\n", React.createElement(_components.h2, {
    id: "approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#approach",
    "aria-label": "approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Approach"), "\n", React.createElement(_components.p, null, "In this problem, we can create a vector of integers, and push all the elements from 0 to mex-1. Then, we have to push maximum element, which is not mex."), "\n", React.createElement(_components.p, null, "Then, we check if all the conditions satisfy. If yes, we find the sum of the array and print it. Else, we print -1."), "\n", React.createElement(_components.p, null, "It is basically a Brute Force Approach, and uses O(N) time and space complexity, ( I know it can be achieved in O(1) too)"), "\n", React.createElement(_components.h2, {
    id: "proof",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#proof",
    "aria-label": "proof permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Proof"), "\n", React.createElement(_components.p, null, "We must include all the elements less than mex at least once. Also, we check all the conditions.\nHence, we can proof that this is correct answer."), "\n", React.createElement(_components.h2, {
    id: "program",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#program",
    "aria-label": "program permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Program"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "/*\nThis template is made by Naman Garg <naman.rustp@gmail.com>\nGitHub : https://github.com/namanlp\nGitLab : https://gitlab.com/namanlp\nWebsite : https://rustp.org\n\nYou can visit https://rustp.org/basic-programs/basic-template/\nfor understanding the template\n\nFeel free to copy the template, but not the solutions :D\nThank You\n */\n\n#![allow(unused)]\n\nuse std::io::stdin;\n\nfn take_int() -> usize {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap();\n}\n\nfn take_vector() -> Vec<usize> {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    let arr: Vec<usize> = input.trim().split_whitespace().map(|x| x.parse().unwrap()).collect();\n    return arr;\n}\n\nfn take_string() -> Vec<char> {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    let vec: Vec<char> = input.trim().chars().collect();\n    return vec;\n}\n\nfn to_string(vec: Vec<char>) -> String { return vec.iter().collect::<String>(); }\n\nfn solve() {\n// ======================= Code Here =========================\n    let n_k_x = take_vector();\n    let n = n_k_x[0];\n    let k = n_k_x[1];\n    let x = n_k_x[2];\n\n    let mut arr = Vec::new();\n\n    if x+1 < k { println!(\"-1\"); return; }\n\n    for i in 0..k {\n        arr.push(i);\n    }\n\n    while arr.len() < n {\n        if x == k {\n            arr.push(x-1);\n        }\n        else {\n            arr.push(x);\n        }\n    }\n\n    if arr.len() > n{ println!(\"-1\"); return; }\n\n    let mut sum =0;\n\n    for i in arr {\n        sum += i;\n    }\n    println!(\"{}\", sum);\n}\n\n\npub fn main() {\n    let t = take_int();\n    for _ in 0..t { solve(); }\n}\n")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "In this article, we discussed solution to Codeforces problem 1870A. MEXanized Array along with proof and program in Rust Language."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Codeforces 1870A solution with proof - Rust Programming",
    description: "In this article, we will discuss solution to Codeforces problem 1870A MEXanized Array along with proof and program in Rust Language.",
    img: "https://rustp.org/Static_Images_DND/Social/Codeforces/Codeforces_1870A_Social.png"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
